<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Determine whether each of the compounds listed below is a conjugated or a non-conjguated
        system.
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <th style="font-size: 15px">Molecule</th>
            <th style="font-size: 15px">Conjugated</th>
            <th style="font-size: 15px">Non-Conjugated</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <stemble-latex content="$\text{a) pent-2-enal}$" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="conjugated" value="conjugated" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="nonConjugated" value="nonConjugated" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{b) heptan-2-one}$" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="conjugated" value="conjugated" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="nonConjugated" value="nonConjugated" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{c) pentanal}$" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="conjugated" value="conjugated" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="nonConjugated" value="nonConjugated" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{d) hept-3-en-2-one}$" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="conjugated" value="conjugated" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="nonConjugated" value="nonConjugated" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'ChemUCI41LBA6A14',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
    };
  },
};
</script>
